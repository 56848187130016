<template>
  <Box>
    <ErrorRow :error="error" />
    <Row>
      <div class="rc-box-col-2 rc-text-value">
        {{ invoice.number() }}
      </div>
      <div class="rc-box-col-2 rc-text-value">
        {{ invoice.createdDateDisplay() }}
      </div>
      <div class="rc-box-col-2 rc-text-value rc-text-centre">
        ${{ invoice.totalBeforeTaxes().toFixed(2) }}
      </div>
      <div class="rc-box-col-1 rc-text-value rc-text-centre">
        ${{ invoice.totalWithTaxes().toFixed(2) }}
      </div>
      <div class="rc-box-col-2 rc-text-value rc-text-centre">
        ${{ invoice.totalPayments().toFixed(2) }}
      </div>
      <Column :width="2" class="rc-text-value rc-text-centre">
        <Box>
          <Row>
            <Column :width="7">
              ${{ invoice.totalRemaining().toFixed(2) }}
            </Column>
            <Column :width="8">
              <Button :go="true" v-if="!invoice.isTotalRemainingZero()" v-on:click="payNow()">
                Pay
              </Button>
            </Column>
          </Row>
        </Box>
      </Column>

      <div class="rc-box-col-2 rc-text-value rc-text-centre">
        {{ invoice.dueDateDisplay() }}
      </div>
      <div class="rc-box-col-1 rc-text-value">
        <InvoicePdfLink :invoiceData="invoiceDataLatest" />
      </div>
      <div class="rc-box-col-1 rc-text-value rc-text-centre" :class="paidBackground">
        {{ invoice.paidDisplay() }}
      </div>
    </Row>
    
    <Row :showBorder="true" v-if="isAdmin">
      <Column :width="15">
       <div class="rc-container rc-text-small">
       
        <StringValueRow v-if="isAdmin" label="Company" :value="invoice.company().find().name()" style="padding:5px;" />
        <Row style="padding:5px;">
          <Column :width="2" class="rc-text-label rc-text-small">Payment Link</Column>
          <Column :width="3" class="rc-text-value rc-text-small"><a :href="invoice.paymentLink()"> Link To Invoice</a> </Column>
          <Column :width="2" class="rc-text-label rc-text-small">Payment Url</Column>
          <Column :width="8" class="rc-text-value rc-text-small"><a :href="invoice.paymentUrl()">Link</a></Column>
        </Row>
        
        <div v-if="isAdmin" class="row" style="padding:5px;">
          <div class="rc-box-col-2 rc-text-label rc-text-small">
           Invoice Id
          </div>
          <div class="rc-box-col-3 rc-text-value rc-text-small">
            {{ invoice.id() }}
          </div>
          <div class="rc-box-col-2 rc-text-label rc-text-small">
           Contract Start
          </div>
          <div class="rc-box-col-3 rc-text-value rc-text-small">
            {{ invoice.company().find().contractStartDate().displayAs_YYYY_MM_DD() }}
          </div>
          <div class="rc-box-col-2 rc-text-label rc-text-small">
           PromoCode
          </div>
          <div class="rc-box-col-3 rc-text-value rc-text-small">
            {{ invoice.company().find().promo() }}
          </div>
          
        </div>
        
        <Row v-if="isAdmin" style="padding:5px;">
         <div class="rc-box-col-2 rc-text-label rc-text-small">
           QBO Invoice ID
          </div>
          <div class="rc-box-col-3 rc-text-value rc-text-small" >
            {{ invoice.integrationReference() }}
          </div>
          <div class="rc-box-col-2 rc-text-label rc-text-small">
           QBO Charge ID
          </div>
          <div class="rc-box-col-3 rc-text-value rc-text-small" >
            {{ invoice.chargeId() }}
          </div>
          
          <div class="rc-box-col-2 rc-text-label rc-text-small">
           QBO Charge Status
          </div>
          <div class="rc-box-col-3 rc-text-value rc-text-small" :class="chargeBackground">
            {{ invoiceDataLatest['chargeStatus'] }}
          </div>
        </Row>
      
        <Row v-if="isAdmin" style="padding:5px;">
          <div class="rc-box-col-2 rc-text-label rc-text-small">
            Invoice Email Sent On
          </div>
          <div class="rc-box-col-3 rc-text-small " :class="sentOnBackground">
            {{ invoice.sentOn().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
          </div>
          
          <div class="rc-box-col-2 rc-text-label rc-text-small">
            Marked as Paid on
          </div>
          <div class="rc-box-col-3 rc-text-small" :class="paidOnBackground">
            {{ invoice.paidDate().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
          </div>
    
          <div class="rc-box-col-2 rc-text-label rc-text-small">
            Thank-you Sent On
          </div>
          <div class="rc-box-col-3 rc-text-small" :class="paidOnBackground">
            {{ invoice.sentOnPaid().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
          </div>
        </Row>
    
        <div v-if="isAdmin" class="row" style="padding:5px;">
          <div class="rc-box-col-2 rc-text-label rc-text-small">
            Operations: 
          </div>
          <div class="rc-box-col-13 rc-text-value rc-text-small">
            {{ invoice.operations() }}
          </div>
        </div>
        
        <Row class="rc-font-medium" v-if="isAdmin" style="padding:5px;">
          <div class="rc-box-col-2 rc-text-label rc-text-small">
            Payouts
          </div>
          <div class="rc-box-col-2 rc-text-value rc-text-small">
            30%={{ (invoice.totalBeforeTaxes()*0.30).toFixed(2) }}
          </div>
          <div class="rc-box-col-2 rc-text-value rc-text-small">
            20%={{ (invoice.totalBeforeTaxes()*0.20).toFixed(2) }}
          </div>
          <div class="rc-box-col-2 rc-text-value rc-text-small">
            10%={{ (invoice.totalBeforeTaxes()*0.10).toFixed(2) }}
          </div>
          <Column :width="9" />
        </Row>
        
        <Row v-if="isAdmin" style="padding:5px;">
          <Column :width="10" />
    
          <Column v-if="!invoice.paid()" :width="3"> 
            <Box>
              <KeyValue 
                title="amountPaid" 
                placeholder="Amount Paid" 
                :disabled="isSaving"
                :clearValue="amountPaidDefault"
                hint="Only add value if different than total"
                v-on:value="setValue" />
            </Box>
          </Column>
          <Column v-else :width="3" />
          
          <Column v-if="invoice.paid()" :width="1">
            <Button label="Not Paid" :disabled="isSaving" v-on:click="notPaid"> Not Paid </Button>
          </Column>
        
          <Column v-else :width="1">
            <br />
            <Button label="Paid" :disabled="isSaving" v-on:click="paid"> Paid </Button>
          </Column>
    
          <Column :width="1">
            <br />
            <Button v-if="canCharge()" :disabled="isSaving" v-on:click="charge"> Charge CC </Button>
          </Column>
        </Row>
        
        <Row v-if="isAdmin">
          <div class="rc-box-col-4" />
          
          <div class="rc-box-col-2">
            <Button label="Charge Details" :disabled="isSaving" v-on:click="chargeDetails"> Charge Details </Button>
          </div>
          
          <div class="rc-box-col-2">
            <Button label="Update Invoice" :disabled="isSaving" v-on:click="rebuild"> Update Invoice </Button>
          </div>
    
          <div class="rc-box-col-2">
            <Button label="Update Pdf" :disabled="isSaving" v-on:click="sendRebuildPdf"> Update PDF </Button>
          </div>
          
          <div class="rc-box-col-1">
            <Button :disabled="isSaving" v-on:click="updateQb"> Update QB </Button>
          </div>
        
          <div class="rc-box-col-2">
            <Button label="Email Invoice Ready" :disabled="isSaving" v-on:click="sendEmail">Email Invoice Ready</Button>
          </div>
    
          <div class="rc-box-col-2">
            <Button label="Email Invoice Paid" :disabled="isSaving" v-on:click="sendEmailThankYou">Email Invoice Paid</Button>
          </div>
    
        </Row>
       
       <!--  -->
       </div>
      </Column>
    </Row>
    
    
  </Box>
</template>

<script>

import { mapGetters } from "vuex";
import Catelog from "@/domain/session/CanadianEnglish.js";
import Invoice from "@/domain/model/invoice/Invoice.js";
import Button from "@/portals/shared/library/button/Button.vue";
import ErrorRow   from '@/components/row/ErrorRow.vue';
import InvoicePdfLink from "./InvoicePdfLink.vue";
import KeyValue from '@/components/input/KeyValue.vue';
import StringValueRow   from "@/components/row/StringValueRow";
import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils  from '@/utils/StringUtils.js';
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'portal-operator-invoice-row',
  components: {
    InvoicePdfLink,
    Button,
    StringValueRow,
    ErrorRow,
    KeyValue,
    Box, Row, Column
  },
  props: {
    invoiceData: Object,
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      error: null,
      catelogKeys: Catelog.KEYS,
      catelog: new Catelog(),
      isSaving: false,
      invoiceDataLatest: {},
      amountPaidDefault: false,
      kvps: {},
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,
    }
  },
  computed: {
    ...mapGetters([
         'auth_connected',
         'domain',
      ]),
    invoice: function() {
      if (this.invoiceDataLatest) {
        return new Invoice(this.domain, this.invoiceDataLatest);
      }
      return new Invoice(this.domain, {});
    },
    sentOnBackground: function() {
      if (!this.invoice.sentOn().isEmpty()) {
        return 'rc-input-good';
      }
      return 'rc-input-major';
    },
    sentOnPaidBackground: function() {
      if (!this.invoice.sentOnPaid().isEmpty()) {
        return 'rc-input-good';
      }
      return 'rc-input-major';
    },
    paidOnBackground: function() {
      if (!this.invoice.paidDate().isEmpty()) {
        return 'rc-input-good';
      }
      return 'rc-input-major';
    },

    chargeBackground: function() {
      if (this.invoice.chargeStatus() === "DECLINED") {
        return 'rc-input-major';
      } else if (this.invoice.chargeStatus() === "CAPTURED" || this.invoice.chargeStatus === "SETTLED") {
        return 'rc-input-good';
      }
      return '';
    },
    paidBackground: function() {
      if (this.invoice.paid()) {
        return 'rc-input-good';
      }
      return '';
    },
  },
  watch: {
    invoiceData() {
      this.invoiceDataLatest = this.invoiceData;
    }
  },
  mounted: function () {
    this.invoiceDataLatest = this.invoiceData;
  },
  methods: {
    rebuild: function() {
      if (this.isSaving) {
        return;
      }
      if (this.auth_connected) {
        this.sendRebuild();
      } else {
        this.$connect();  
      }
    },
    sendRebuild: function() {
      this.isSaving = true;
      this.error = null;
      this.event = this.domain.events().invoice().rebuild(this.invoice);
      this.event.send(this.rebuildListener);
    },
    rebuildListener: function(response) {
      this.isSaving = false;
      if (response.error()) {
        this.error = response.error();
      } else {
        var payload = response.payload();
        if (payload) {
          var updatedInvoice = payload.valuesFor(Invoice.MODEL_NAME);
          if (updatedInvoice) {
            this.invoiceDataLatest = updatedInvoice.data;
          }
        }
      }
      this.kvps = {};
      this.amountPaidDefault = true;
    },
    sendRebuildPdf: function() {
      this.isSaving = true;
      this.error = null;
      this.event = this.domain.events().invoice().rebuildPdf(this.invoice);
      this.event.send(this.rebuildPdfListener);
    },
    rebuildPdfListener: function(response) {
      this.isSaving = false;
      if (response.error()) {
        this.error = response.error();
      }
    },
    updateQb: function() {
      this.isSaving = true;
      this.error = null;
      this.event = this.domain.events().invoice().rebuildQb(this.invoice);
      this.event.send(this.updateQbListener);
    },
    updateQbListener: function(response) {
      this.isSaving = false;
      if (response.error()) {
        this.error = response.error();
      }
    },
    payNow: function() {
      var params = {
          invoiceNumber: this.invoice.number(), 
      };
      this.$router.push( {
            name: ConstUtils.ROUTES.INVOICE.PAYMENT_LINK,
            query: params,
          });
    },
    
    notPaid: function() {
      if (this.isSaving) {
        return;
      }
      if (this.auth_connected) {
        this.sendNotPaid();
      } else {
        this.$connect();  
      }
    },
    sendNotPaid: function() {
      this.isSaving = true;
      this.error = null;
      
      var invoice = this.invoice.copy();
      invoice.withNotPaid();
      
      this.event = this.domain.events().invoice().save(invoice);
      this.event.send(this.rebuildListener);
    },
    setValue: function(kvp) {
      if (this.domain) {
        ContentUtils.set(this.kvps, kvp, this.domain.logger());
      }
    },
    
    paid: function() {
      if (this.isSaving) {
        return;
      }
      if (this.auth_connected) {
        this.sendPaid();
      } else {
        this.$connect();  
      }
    },
    sendPaid: function() {
      this.isSaving = true;
      this.error = null;
      
      var invoice = this.invoice.copy();
      invoice.withPaid();
      
      this.event = this.domain.events().invoice().paid(invoice, this.kvps);
      this.event.with(Invoice.Fields.NUMBER, invoice.number());
      this.event.send(this.rebuildListener);
    },
    canCharge: function() {
      var card = this.invoice.company().find().invoiceSettings().find().card();
      var chargeId = this.invoice.chargeId();
      var paid = this.invoice.paid();
      if (paid) {
        return false;
      }
      if (card.isNew()) {
        return false;
      }
      if (!StringUtils.isEmpty(chargeId)) {
        return false;
      }
      return true;
    },
    charge: function() {
      if (this.isSaving) {
        return;
      }
      if (this.auth_connected) {
        this.sendCharge();
      } else {
        this.$connect();  
      }
    },
    sendCharge: function() {
      this.isSaving = true;
      this.error = null;
      
      var invoice = this.invoice.copy();
      
      this.event = this.domain.events().invoice().charge(invoice, this.kvps);
      this.event.with(Invoice.Fields.NUMBER, invoice.number());
      this.event.send(this.rebuildListener);
    },
    chargeDetails: function() {
      this.isSaving = true;
      this.error = null;
      this.event = this.domain.events().invoice().chargeDetails(this.invoice);
      this.event.send(this.rebuildListener);
    },
    
    sendEmail: function() {
      this.isSaving = true;
      this.error = null;
      
      var invoice = this.invoice.copy();
      
      this.event = this.domain.events().invoice().emailTo(invoice);
      this.event.send(this.rebuildListener);
    },
    sendEmailThankYou: function() {
      this.isSaving = true;
      this.error = null;
      
      var invoice = this.invoice.copy();
      
      this.event = this.domain.events().invoice().emailThanks(invoice);
      this.event.send(this.rebuildListener);
    }
  },
}
</script>